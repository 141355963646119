import React, {useState, Fragment, useEffect} from "react"
import {Link} from "react-router-dom"
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Progress
} from "reactstrap"
import {Modal, Row, Col, Spinner} from "react-bootstrap"
import {connect, useDispatch} from "react-redux"
import {withRouter} from "react-router-dom"
import moment from "moment"
import "./positions.css"
import Switch from "../Common/Switch"
import DeletePrompt from "../Common/DeletePrompt"
import SharePosition from "./SharePosition"
import InviteCandidate from "./InviteCandidate"
import {
    companyPositionDuplicationAction,
    deleteCompanyPositionAction,
    getCompanyPositionQuestionsAction,
    getCompanyPositionsAction,
    updateCompanyPositionAction
} from "../../../store/actions/CompanyActions"
import {getCompany, getCompanyPositions, getMeiaAddedMessage} from "../../../store/selectors/CompanySelector"



const PositionList = (props) => {
    const [reloadVal, setReloadVal] = useState(0)
    const {getPositions, getMessage, showAlert, getCompany} = props

    const [loader, setLoader] = useState(false)

    const [shareModal, setShareModal] = useState(false)
    const [inviteModal, setInviteModal] = useState({show: false, position: undefined})
    const [deleteModal, setDeleteModal] = useState({show: false, func: undefined})
    const [deactivateModal, setDeactivateModal] = useState({show: false, func: undefined})

    return (
        <>
            {getPositions ? getPositions?.data?.map((pos, key) => {

                    let posColor = " bg-col-green-light"
                    if (pos?.status === "draft") posColor = " bg-col-red-light"
                    if (pos?.status === "inActive" || pos?.status === "closed") posColor = " bg-col-red-light "

                    let positionTitle = pos?.title?.split(" ")
                    console.log({positionTitle});
                    console.log({title:pos?.title});
                    console.log({length:positionTitle.length});
                    let title = ""
                    if (positionTitle && positionTitle.length > 1) {
                        title = positionTitle[0][0] + positionTitle[1][0]
                    } else if (positionTitle.length === 1) {
                        title = positionTitle[0][0]
                    } else {
                        title = positionTitle[0][0] + positionTitle[0][1]
                    }
                    title = title ? title.toUpperCase() : ""

                    return (
                        <Fragment key={key}>
                            <tr className="align-items-center">
                                <td>
                                    <div
                                        className={`rounded-circle  text-center position-chars ${posColor} img-thumbnail`}
                                    >
                                        {title || "NF"}
                                    </div>
                                </td>
                                <td>
                                    <div className="text-truncate font-size-14">
                                        <Link
                                            to={`/position/detail/${pos?.id}`}
                                            className="text-dark"
                                        >
                                            {pos?.title}
                                        </Link>
                                        <span className="d-block text-muted">
                      <small>Questions: ({pos?.question_count})</small>
                    </span>
                                    </div>
                                </td>
                                <td>{pos?.location}</td>
                                <td>{moment(pos?.closing_date).format("MMM DD, YYYY")}</td>
                                <td>{pos?.CreatedBy?.first_name + " " + pos?.CreatedBy?.last_name}</td>
                                <td>
                                    <Row className="align-items-center text-center">
                                        <Col lg={12}>
                                            <Progress
                                                color="primary"
                                                value={
                                                    Math.round(
                                                        (pos?.completed_interview_count /
                                                            pos?.total_interview_count) *
                                                        100
                                                    ) || 0
                                                }
                                            />
                                        </Col>
                                        <Col lg={12} className="12">
                                            {pos?.completed_interview_count}/{pos?.total_interview_count}
                                        </Col>
                                    </Row>
                                </td>
                                <td>
                                    <Row className="align-items-center">
                                        <Col lg={12}>
                                            <Progress
                                                color="primary"
                                                value={
                                                    Math.round(
                                                        (pos?.completed_evaluated_count /
                                                            pos?.total_interview_count) *
                                                        100
                                                    ) || 0
                                                }
                                            />
                                        </Col>
                                        <Col lg={12} className="text-center">
                                            {pos?.completed_evaluated_count}/{pos?.total_interview_count}
                                        </Col>
                                    </Row>
                                </td>
                                <td>
                                    <div className="d-flex">
                                    {pos?.status === "draft" ? (
                                        "Draft"
                                    ) : (
                                        // loader ?
                                        // <Spinner id={pos?.id} animation="grow" variant="primary" />
                                        // :
                                        <Switch
                                            isOn={pos?.status === "open" ? true : false}
                                            forId={`position-status-item-${pos?.id}`}
                                            handleToggle={() => {
                                                setLoader(true)
                                                pos?.status === "open"
                                                    ? setDeactivateModal({
                                                        show: true,
                                                        func: () => {
                                                            props.updateCompanyPosition({
                                                                position_id: pos?.id,
                                                                position_data: {
                                                                    status: "close"
                                                                }
                                                            }, props, () => setLoader(false))
                                                            setDeactivateModal(undefined)
                                                        }
                                                    })
                                                    :
                                                    props.updateCompanyPosition({
                                                        position_id: pos?.id,
                                                        position_data: {
                                                            status: "open"
                                                        }
                                                    }, props, () => setLoader(false))
                                            }}
                                        />
                                    )}
                                    </div>
                                </td>
                                <td className="text-center">
                                    <UncontrolledDropdown>
                                        <DropdownToggle href="#" className="card-drop" tag="i">
                                            <i className="mdi mdi-dots-horizontal font-size-18"/>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {pos?.status === "draft" ? (
                                                <>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            // dispatch(getPosition(pos?.id))
                                                            props.history.push(
                                                                `position/edit/${pos?.id}`
                                                            )
                                                        }}
                                                    >
                                                        Complete Setup
                                                    </DropdownItem>
                                                </>
                                            ) : (
                                                <>
                                                    <DropdownItem
                                                        onClick={() =>
                                                            props.history.push(`/position/detail/${pos?.id}`)
                                                        }
                                                    >
                                                        Review Position
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setShareModal({show: true, pos: pos})} className={'disabled'}>
                                                        Share Interview
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => setInviteModal({position: pos, show: true})}
                                                        className={'disabled'}>
                                                        Invite a New Candidate
                                                    </DropdownItem>

                                                    <DropdownItem onClick={() => {
                                                        props.history.push(`/position/edit/${pos?.id}`)
                                                    }}
                                                                  className={'disabled'}
                                                    >
                                                        Edit Position
                                                    </DropdownItem>
                                                </>
                                            )}
                                            <DropdownItem
                                                onClick={() => props.positionDuplication({"position_id": pos.id}, props, () => {
                                                    showAlert();
                                                    setReloadVal(reloadVal+1)
                                                })}
                                                className={'disabled'}>
                                                Duplicate Position
                                            </DropdownItem>
                                            <DropdownItem className="text-danger"
                                                          onClick={() => {
                                                              setDeleteModal({
                                                                  show: true,
                                                                  func: () => {
                                                                      const data = {
                                                                          position_id: pos?.id
                                                                      }
                                                                      props.deleteCompanyPosition(data, props, () => showAlert())

                                                                      setDeleteModal(undefined)
                                                                  }
                                                              })
                                                          }
                                                          }
                                            >
                                                Delete
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                        </Fragment>
                    )
                })
                : null}
            <Modal centered={true} show={shareModal?.show} onHide={() => {
                setShareModal(undefined)
            }}>

                {/* TODO: send data to the model */}

                <SharePosition company={getCompany} position={shareModal?.pos} toggle={() => {
                    setShareModal(undefined)
                }}/>
            </Modal>
            <Modal centered={true} show={inviteModal?.show} onHide={() => {
                setInviteModal(undefined)
            }}>
                <InviteCandidate company={getCompany?.name} showAlert={()=>showAlert()} position={inviteModal?.position} toggle={() => {
                    setInviteModal(undefined)
                }}/>
            </Modal>
            <Modal centered show={deleteModal?.show} onHide={() => {
                setDeleteModal(undefined)
            }}>
                <DeletePrompt
                    title={"Are you sure you want to delete this position?"}
                    message={
                        "Candidates will no longer be able to interview for this position. This action cannot be undone."
                    }
                    func={() => deleteModal?.func()}
                    toggle={() => {
                        setDeleteModal(undefined)
                    }}
                />
            </Modal>
            <Modal centered={true} show={deactivateModal?.show} onHide={() => {
                setDeactivateModal(undefined)
            }}>
                <DeletePrompt
                    title={"Are you sure you want to deactivate?"}
                    btnText={"Confirm"}
                    func={() => deactivateModal?.func()}
                    toggle={() => {
                        setDeactivateModal(undefined)
                    }}
                />
            </Modal>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        getPositions: getCompanyPositions(state),
        getMessage: getMeiaAddedMessage(state),
        getCompany: getCompany(state)
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getCompanyPositions: (data, props, cb) => dispatch(getCompanyPositionsAction(data, props.history, cb)),
        positionDuplication: (data, props, cb) => dispatch(companyPositionDuplicationAction(data, props.history, cb)),
        updateCompanyPosition: (data, props, cb) => dispatch(updateCompanyPositionAction(data, props.history, cb)),
        deleteCompanyPosition: (data, props, cb) => dispatch(deleteCompanyPositionAction(data, props.history, cb)),
        getCompanyPositionQuestions: (data, props, cb) => dispatch(getCompanyPositionQuestionsAction(data, props.history, cb))

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PositionList))
