import React, {useState} from "react"

import {Link, matchPath, withRouter} from "react-router-dom"
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar"

import LogoutPage from "./Logout"
/// Image
import avatar from "../../../images/default.svg"
import {Button, Dropdown} from "react-bootstrap"
import {Progress} from "reactstrap"
import {
    getCompanyCandidateFilterDataSelector,
    getCompanyCandidates,
    getCompanySubscription,
    getMeiaAddedMessage
} from "../../../store/selectors/CompanySelector"
import {connect} from "react-redux"
import moment from "moment";

const Header = ({onNote, profile, getSubscription, history, location}) => {
    function toggleFullscreen() {

        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                )
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }


    const [menu, setMenu] = useState(false)
    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left"></div>

                        <ul className="navbar-nav header-right main-notification">

                            <Dropdown
                                as="li"
                                className="nav-item dropdown notification_dropdown "
                                onClick={() => setMenu(!menu)}
                            >
                                <Dropdown.Toggle
                                    variant=""
                                    as="a"
                                    // TODO: subscription fix
                                    className={`nav-link  ai-icon i-false c-pointer ${getSubscription?.limit?.positions?.allowed === getSubscription?.limit?.positions && 'text-danger'}`}
                                    // href="#"
                                    // getSubscription?.limit?.positions?.allowed === getSubscription?.limit?.positions
                                    role="button"
                                    data-toggle="dropdown"
                                >
                                    <span className="d-inline-block mr-2"
                                          style={{
                                              top: "-5px",
                                              position: "relative",
                                              color: (!getSubscription ? "red" : "")
                                          }}>{!getSubscription ? "Subscription expired" : "Monthly Usage Limit"}</span>
                                    <i style={{color: (!getSubscription ? "red" : "")}} className="bx bx-layer"/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="right" className="mt-2">
                                    {/*<PerfectScrollbar className="widget-media dz-scroll p-3 height380">*/}
                                    <ul className="timeline">
                                        <li className="dropdown">
                                            <div className="monthUsageLimit" style={{padding: "11px"}}>
                                                <span className="mb-2 d-block">
                                                    Completed Candidates
                                                    <span className="float-right">
                                                        {getSubscription?.limit?.interviews?.used}/{getSubscription?.limit?.interviews?.allowed}
                                                    </span>
                                                </span>
                                                <Progress className="mb-2" color="primary" value={
                                                    Number(getSubscription?.limit?.interviews?.used) / Number(getSubscription?.limit?.interviews?.allowed) * 100
                                                }/>
                                                <span className="mb-2 d-block">
                                                    Positions
                                                    <span className="float-right">
                                                        {getSubscription?.limit?.positions?.used}/{getSubscription?.limit?.positions?.allowed}
                                                    </span>
                                                </span>
                                                <Progress className="mb-2" color="primary" value={
                                                    Number(getSubscription?.limit?.positions?.used) / Number(getSubscription?.limit?.positions?.allowed) * 100
                                                }/>
                                                <span className="mb-2 d-block">
                                                    Users
                                                    <span className="float-right">
                                                        {getSubscription?.limit?.users?.used}/{getSubscription?.limit?.users?.allowed}
                                                    </span>
                                                </span>
                                                <Progress className="mb-2" color="primary" value={
                                                    Number(getSubscription?.limit?.users?.used) / Number(getSubscription?.limit?.users?.allowed) * 100
                                                }/>
                                                <div className="row" style={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'baseline',
                                                    margin: 0
                                                }}>
                                                    <span onClick={() => window.location.replace('https://digitalhire.com')}
                                                          style={{cursor: "pointer"}} className="btn btn-primary">
                                                        Upgrade
                                                    </span>
                                                    <span className=" text-subtitle" style={{textAlign: 'center'}}>
                                                        {getSubscription?.to_date
                                                            ? moment(getSubscription?.to_date).format("MMM DD, YYYY")
                                                            : "N/A"}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                    {/*</PerfectScrollbar>*/}
                                </Dropdown.Menu>
                            </Dropdown>

                            <li className="nav-item dropdown notification_dropdown dropdown">
                                <Link
                                    to={`#`}
                                    onClick={() => {
                                        toggleFullscreen()
                                    }}
                                    className="nav-link i-false c-pointer dropdown-toggle"
                                >

                                    <i className="bx bx-fullscreen"></i>
                                </Link>
                            </li>

                            <Dropdown as="li" className="nav-item dropdown header-profile">
                                <Dropdown.Toggle
                                    variant=""
                                    as="a"
                                    className="nav-link i-false c-pointer"
                                    // href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                >

                                    <img src={profile?.image || avatar} width={20} alt=""/>
                                    <div className="header-info">
                                        <span>
                                        {profile?.first_name} {profile?.last_name}
                                        </span>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="right" className="mt-2">
                                    <Link to="/account" className="dropdown-item ai-icon">
                                        <svg
                                            id="icon-user1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="text-primary"
                                            width={18}
                                            height={18}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                                            <circle cx={12} cy={7} r={4}/>
                                        </svg>
                                        <span className="ml-2">Account </span>
                                    </Link>
                                    <Link to="/company-profile" className="dropdown-item ai-icon">
                                        <i className="text-primary flaticon-381-newspaper"></i>
                                        <span className="ml-2">Company Profile </span>
                                    </Link>
                                    <LogoutPage/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>


    )
}

const mapStateToProps = (state) => {
    return {
        getSubscription: getCompanySubscription(state)
    }
}

export default withRouter(connect(mapStateToProps, null)(Header))
