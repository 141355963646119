import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Progress, Spinner } from "reactstrap"
import { Modal } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"


import "./positions.css"
import Alert from "./../Common/Alert"
import ShareInterview from "./common/ShareInterview"
import QuestionsDetails from "./common/QuestionsDetails"
import UserPositionDetails from "./common/UserPositionDetails"
import AddTeamMember from "./common/AddTeamMember"
import PositionActions from "./common/PositionActions"
import InviteCandidate from "./InviteCandidate"
import PageTitle from "../../layouts/PageTitle"
import { connect } from "react-redux"
import {
  getCompanyCurrentPosition,
  getMeiaAddedMessage,
  getCompany
} from "../../../store/selectors/CompanySelector"
import { getCompanyPositionAction } from "../../../store/actions/CompanyActions"

const PositionDetail = (props) => {

  const { position, getCurrentPosition, getCompany, getMessage } = props
  const [inviteModal, setInviteModal] = useState(false)
  const [alert, showAlert] = useState(false)
  const [pos, setpos] = useState({});

  useEffect(() => {
    setpos(getCurrentPosition)
  }, [getCurrentPosition]);

  useEffect(() => {
    setpos({})
    const pos_id = String(props?.location?.pathname).split("/position/detail/")[1]
    const data = {
      position_id: pos_id
    }
    props.getCompanyPosition(data, props)
  }, [])

  useEffect(() => {
    if (getMessage?.updated && alert) {
      const pos_id = String(props?.location?.pathname).split("/position/detail/")[1]
      const data = {
        position_id: pos_id
      }
      props.getCompanyPosition(data, props)
    }
  }, [getMessage?.updated, alert])

  return (
    <>
      {/*<PageTitle activeMenu="Position Detail" motherMenu="Dashboard" />*/}
      <Alert alert={alert} type={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "error"}
        variant={(getMessage?.success || getMessage?.delete || getMessage?.updated) ? "success" : getMessage?.error && "danger"}
        msg={getMessage?.success || getMessage?.delete || getMessage?.error || getMessage?.updated}
        dismissAlert={() => showAlert(false)}
      />
      <div>
        <Row>
          <Col lg={8}>
            <UserPositionDetails position={getCurrentPosition} company={getCompany}/>
            {/*<ShareInterview setInviteModal={setInviteModal} position={getCurrentPosition} />*/}
            <QuestionsDetails position={getCurrentPosition} />
          </Col>
          <Col lg={4}>
            <Card style={{ height: "auto" }}>
              <CardBody>
                <Row>
                  <Col lg={7}>
                    <h5>Position Statistics</h5>
                  </Col>
                  <Col>
                  <span className="float-right">
                    <Link to={"/report"}>View Reports</Link>
                  </span>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h5>Interviews</h5>
                    <div>
                      <Row className="align-items-center">
                        <Col lg={6}>
                          <Progress
                            color="primary"
                            value={
                              Math.round(
                                (getCurrentPosition?.completed_interview_count /
                                  getCurrentPosition?.interview_count) *
                                1 *
                                100
                              ) || 0
                            }
                          />
                        </Col>
                        <Col lg={6}>
                          {/*{getCurrentPosition.id}*/}
                          {getCurrentPosition?.completed_interview_count}/
                          {getCurrentPosition?.interview_count}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col>
                    <h5>Evaluations</h5>
                    <div>
                      <Row className="align-items-center">
                        <Col lg={6}>
                          <Progress
                            color="primary"
                            value={
                              Math.round(
                                (getCurrentPosition?.completed_evaluated_count /
                                  getCurrentPosition?.interview_count) *
                                1 *
                                100
                              ) || 0
                            }
                          />
                        </Col>
                        <Col lg={6}>
                          {getCurrentPosition?.completed_evaluated_count}/
                          {getCurrentPosition?.interview_count}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <AddTeamMember position={getCurrentPosition}  showAlert={() => showAlert(true)}/>
            <PositionActions position={pos} props={props} showAlert={() => showAlert(true)} />
          </Col>
        </Row>
        <Modal centered={true} show={inviteModal} onHide={() => {
          setInviteModal(!inviteModal)
        }}>
          <InviteCandidate position={getCurrentPosition} showAlert={() => showAlert(true)} toggle={() => {
            setInviteModal(!inviteModal)
          }} />
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    getCompany: getCompany(state),
    getCurrentPosition: getCompanyCurrentPosition(state),
    getMessage: getMeiaAddedMessage(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCompanyPosition: (data, props, cb) => dispatch(getCompanyPositionAction(data, props.history, cb))
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PositionDetail))
