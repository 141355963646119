import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Col, Spinner, Row } from "reactstrap";

import profile from "../../../images/default.svg";

const TeamMember = ({ data, loader, history }) => {
  const [teamSearch, setTeamSearch] = useState("");

  return (
    <div className="col-12">
      <div className="card" style={{ height: "288px" }}>
        <div className="">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title">Team Members</h5>
            <button
              onClick={() => history.push("/team-members")}
              className=" btn btn-primary disabled"
            >
              Add Member
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mb-2">
              <input
                type="text"
                value={teamSearch}
                onChange={(e) => setTeamSearch(e.target.value)}
                className="form-control"
                placeholder="Search Member"
              />
              {/* <hr className="my-2" /> */}
            </div>

            {loader ? (
              <Col style={{ textAlignLast: "center" }}>
                <Spinner
                  size="sm"
                  className="m-auto text-center"
                  color="success"
                />
              </Col>
            ) : data ? (
              <div
                className="custom-scroll col-12"
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "150px",
                }}
              >
                {data.map(
                  (e, i) =>
                    e.AddedMemberUser?.first_name
                      ?.toLowerCase()
                      ?.includes(teamSearch.toLowerCase()) && (
                      <Row className=" mb-3 align-items-center" key={i}>
                        <Col md={2}>
                          <img
                            src={e.AddedMemberUser?.image || profile}
                            className="rounded-circle avatar-xs p-1"
                            alt=""
                            width={"50"}
                          />
                        </Col>
                        <Col style={{ borderWidth: 2 }}>
                          <h5 className="d-inline-block font-size-14">
                            {e.AddedMemberUser?.first_name +
                              " " +
                              e.AddedMemberUser?.last_name}
                            <span className="text-muted">
                              <small>
                                {" "}
                                {e.AddedMemberUser?.Roles.map(
                                  (role) => role.name + " "
                                )}
                              </small>
                            </span>
                          </h5>
                        </Col>
                      </Row>
                    )
                )}
              </div>
            ) : (
              <div className="col-12">
                <span className="d-block text-muted">
                  No Team Member Is Added
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
