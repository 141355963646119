/// Menu
import MetisMenu from "metismenujs"
import React, {Component, useContext, useEffect} from "react"
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar"
/// Link
import {Link} from "react-router-dom"
import useScrollPosition from "use-scroll-position"
import {ThemeContext} from "../../../context/ThemeContext"
import avatar from "../../../images/default.svg"
import "./sidebar.css"
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class MM extends Component {
    componentDidMount() {
        this.$el = this.el
        this.mm = new MetisMenu(this.$el)
    }

    componentWillUnmount() {
        //  this.mm("dispose");
        // console.log(this.mm);
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

const SideBar = ({profile, company, permissions}) => {
    const {iconHover, sidebarposition, headerposition, sidebarLayout} = useContext(ThemeContext)
    useEffect(() => {
        var btn = document.querySelector(".nav-control")
        var aaa = document.querySelector("#main-wrapper")

        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle")
        }

        btn.addEventListener("click", toggleFunc)
    }, [])
    let scrollPosition = useScrollPosition()
    /// Path
    let path = window.location.pathname
    path = path.split("/")
    path = path[path.length - 1]
    /// Active menu


    const renderTooltip = (props, text) => {
        // if (sidebarLayout.value === "horizontal"
        return (
            <Tooltip style={{width: "300px"}} {...props}>
                {text}
            </Tooltip>
        )
    }

    let deshBoard = ["home"],
        position = ["position"],
        media = ["media"],
        candidate = ["candidate"],
        evaluation = ["evaluation"],
        report = ["report", "report-candidate", "report-positions", "report-evaluations"],
        account = ["account"],
        companyProfile = ["company-profile"],
        teamMembers = ["team-members"],
        payment = ["payment"],
        gettingStarted = ["getting-started"]
    return (
        <div
            className={`deznav ${iconHover} ${
                sidebarposition.value === "fixed" && sidebarLayout.value === "horizontal" && headerposition.value === "static"
                    ? scrollPosition > 120
                        ? "fixed"
                        : ""
                    : ""
            }`}
        >
            <PerfectScrollbar className="deznav-scroll">
                <div className="main-profile">
                    <img src={company?.logo || avatar} alt="" className="rounded-circle"/>
                    {/*<Link to={"/account"}><i className="fa fa-cog" aria-hidden="true"></i></Link> *!/*/}
                    <h5 className="mb-0 fs-20 text-black ">{company?.name}</h5>
                    {/*<p className="mb-0 fs-14 font-w400">{profile?.email}</p>*/}
                </div>
                <MM className="metismenu metismenu2" id="menu">
                    <li className={`${deshBoard.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/home"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Dashboard`)}>
                                <i className="bx bx-home-circle"></i>

                            </OverlayTrigger>
                            <span className="nav-text">Dashboard</span>

                        </Link>
                    </li>
                    <li className={`${position.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/position"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Positions`)}>
                                <i className="bx bxs-envelope"></i>

                            </OverlayTrigger>
                            <span className="nav-text">Positions</span>
                        </Link>
                    </li>
                    <li className={`${media.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/media"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Media Library`)}>
                                <i className="bx bxs-cloud-upload"></i>

                            </OverlayTrigger>
                            <span className="nav-text">Media Library</span>

                        </Link>
                    </li>
                    <li className={`${candidate.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/candidate"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Candidate`)}>
                                <i className="bx bxs-user-detail"></i>

                            </OverlayTrigger>
                            <span className="nav-text">Candidates</span>

                        </Link>
                    </li>
                    <li className={`${evaluation.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/evaluation"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Evaluations`)}>
                                <i className="bx bx-award"></i>

                            </OverlayTrigger>
                            <span className="nav-text">Evaluations</span>

                        </Link>
                    </li>
                    <li className={`${report.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/report"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Reports`)}>
                                <i className="bx bx-calendar-check"></i>
                            </OverlayTrigger>
                            <span className="nav-text">Reports</span>
                        </Link>
                    </li>
                    <li className={"hover-class"}>
                        <a href="https://digitalhire.com/faqs-employers/" target="_blank" className="ai-icon">

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `FAQS`)}>
                                <i className="bx bx-question-mark"></i>

                            </OverlayTrigger>
                            <span className="nav-text">FAQS</span>

                        </a>
                    </li>
                    <li className={`${gettingStarted.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/getting-started"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `How To Guide`)}>
                                <i className='bx bx-chalkboard'></i>
                            </OverlayTrigger>
                            <span className="nav-text">How To Guide</span>
                        </Link>
                    </li>
                    <div className="main-profile mt-3" style={{padding: 0, paddingLeft: "50px", textAlign: "left"}}>
                        <h6 className="text-subtitle">Settings</h6>
                    </div>

                    <li className={`${account.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/account"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `My Account`)}>
                                <i className="bx bx-user-circle"></i>
                            </OverlayTrigger>
                            <span className="nav-text">My Account</span>
                        </Link>
                    </li>
                    {permissions && permissions?.includes("view_payments") && (
                        <li className={`${payment.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                            <Link className="ai-icon" to={"/payment"}>

                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={props => renderTooltip(props, `Payments`)}>
                                    <i className="bx bx-credit-card"></i>
                                </OverlayTrigger>
                                <span className="nav-text">Payments</span>

                            </Link>
                        </li>
                    )}
                    <li className={`${companyProfile.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/company-profile"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Company Profile`)}>
                                <i className="bx bx-building-house"></i>
                            </OverlayTrigger>
                            <span className="nav-text">Company Profile</span>
                        </Link>
                    </li>
                    <li className={`${teamMembers.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="ai-icon" to={"/team-members"}>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={props => renderTooltip(props, `Team Members`)}>
                                <i className="bx bxs-group"></i>
                            </OverlayTrigger>
                            <span className="nav-text">Team Members</span>
                        </Link>
                    </li>
                </MM>
                <div className="copyright">
                    <p>
                        2025 © <strong>DigitalHire</strong>
                    </p>
                </div>
            </PerfectScrollbar>
        </div>
    )
}

export default SideBar
