import React, {Fragment, useEffect, useState} from "react"
import {Card, Badge, Col, Spinner, Row} from "reactstrap"
import {Modal} from "react-bootstrap"
import {SRLWrapper} from "simple-react-lightbox"
import {withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import VideoThumbnail from "react-video-thumbnail"

import "./style.css"
import Alert from "./../Common/Alert"
import profile from "../../../images/profile/8.jpg"
import MediaTabs from "./MediaTabs"
import DeletePrompt from "./../Common/DeletePrompt"
import {
    addCompanyMediaAction,
    deleteCompanyMediaAction,
    getCompanyMediaAction,
} from "../../../store/actions/CompanyActions"
import {getCompanyMedia, getMeiaAddedMessage} from "../../../store/selectors/CompanySelector"
import UploadBox from "./UploadBox"
import {uploadMediaToAWSAction} from "../../../store/actions/MediaActions"
import moment from "moment"

const MediaLibrary = (props) => {
    const response = props?.responseMessage

    const [loader, setLoader] = useState(false)
    const [listoader, setlistLoader] = useState(false)
    const [alert, showAlert] = useState(false)
    const [thumbnail, setThumbNail] = useState("")
    const [video, setVideo] = useState("")
    const [deleteModal, setDeleteModal] = useState(false)
    const [mediaId, setMediaId] = useState(undefined)
    const [activeTab, setactiveTab] = useState("")

    // console.log(video)
    const onDelete = (id) => {
        setDeleteModal(true)
        setMediaId(id)
    }

    useEffect(() => {
        setlistLoader(true)
        props.loadCompanyMedia({}, props, () => setlistLoader(false))
    }, [])

    function handleAcceptedFiles(files) {
        files?.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )

        files?.map((file) => {
            const media_key = "company/" + file.lastModified + "." + file.type.split("/")[1]

            const data = {
                media_data: {
                    url: media_key,
                    thumbnail: null,
                    name: file.name.split(".")[0],
                    type: file.type.includes("video") ? "video" : "image",
                },
            }

            setLoader(true)
            props.uploadMediaToAWS(
                {
                    media_key,
                    file,
                },
                props,
                () => {
                    props.addCompanyMedia(data, props, () => {
                        setLoader(false)
                        showAlert(true)
                    })
                }
            )
        })
    }

    useEffect(() => {
        if (alert) {
            if (activeTab) {
                filterBy(activeTab)
            } else {
                setlistLoader(true);
                props.loadCompanyMedia({}, props, () => setlistLoader(false))
            }
        }
    }, [response?.success, response?.delete, alert])

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const filterBy = (value) => {
        console.log("VALUE::", value)
        const data = {
            filter_options: {
                search_string: value,
                // sort_array: [["id", "DESC"], ["createdAt", "ASC"]]
            },
        }
        setlistLoader(true)
        props.loadCompanyMedia(data, props, () => {
            setlistLoader(false)
            // showAlert(true)
        })
    }

    const handleTabChange = (tab) => {
        setactiveTab(tab)
        filterBy(tab)
    }

    const deleteMedia = () => {
        if (mediaId) {
            const data = {media_id: mediaId}
            props.deleteCompanyMedia(data, props, () => {
                showAlert(true)
                setDeleteModal(undefined)
            })
        }
    }

    const RenderType = ({type}) => {
        return type === "image" ? (
            <span className="badge badge-success badge-l  light ml-1">Image</span>
        ) : (
            type === "video" && <span className="badge badge-danger badge-l light">Video</span>
        )
    }

    return (
        <Fragment>
            <div className="pb-2">
                <div className="gradient-banner text-left text-white">
                    <h4 className="fw-bold text-white ">✨ We’ve Moved to an AI-Powered Platform!</h4>
                    <p className="mt-3">
                        We’ve upgraded to a smarter and more efficient platform powered by advanced AI technology. All your interviews have been seamlessly migrated to the new platform, and new candidates will now appear there. Don’t worry 😊, you can still return to this link anytime to access old candidate details.
                    </p>
                    <a href="https://beta.app.digitalhire.com/auth/sign-in" target="_blank" className="btn btn-custom mt-3">Go to New Platform</a>
                </div>
            </div>
            {/*<PageTitle activeMenu="Media Library" motherMenu="Dashboard" />*/}

            {(response?.success || response?.delete) && (
                <Alert
                    alert={alert}
                    type={response?.success || response?.delete ? "success" : response?.error && "error"}
                    variant={response?.success || response?.delete ? "success" : response?.error && "danger"}
                    msg={response?.success || response?.delete || response?.error}
                    dismissAlert={() => showAlert(false)}
                />
            )}
            <div className="row">
                {loader ? (
                    <div className="text-center" style={{height: "150px", verticalAlign: "middle", width: "100%"}}>
                        <Spinner size="lg" className="m-auto text-center" color="success"/>
                    </div>
                ) : (
                    <UploadBox handleAcceptedFiles={handleAcceptedFiles} />
                )}

                {/* <Col lg="12">
          <div className="form-inline">
            <div className="form-group" style={{ height: "80px" }}>
              <select
                className="form-control"
                onChange={(value) => filterBy(value)}
              >
                <option value="">Show All</option>
                <option value="video">Videos</option>
                <option value="image">Images</option>
              </select>
            </div>
          </div>
        </Col> */}
                <Col lg={12} className="mt-4 mb-2">
                    {/* <Row className=""> */}
                    <MediaTabs tab={activeTab} setTab={(value) => handleTabChange(value)}/>
                    {/* </Row>  */}
                </Col>
                {listoader ? (
                    <Col style={{textAlignLast: "center"}}>
                        <Spinner size="lg" className="m-auto text-center" color="success"/>
                    </Col>
                ) : props?.media ? (
                    props?.media?.rows?.map((med) => {
                        console.log(med);
                        return (
                            <Col lg={3} md={6} sm={12} className="mb-3" key={med.id}>
                                <div className="media-box">
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            maxHeight: "180px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {med?.type === "image" && (
                                            <SRLWrapper options={options}>
                                                <a href={med.url}>
                                                    {" "}
                                                    <img
                                                        height="166px"
                                                        width="100%"
                                                        src={med.url}
                                                        alt=""
                                                        className="d-block"
                                                    />{" "}
                                                </a>
                                                <div className="row sp4">
                                                    <div className="col-12 mb-1"></div>
                                                </div>
                                            </SRLWrapper>
                                        )}
                                        {med?.type === "video" && (
                                            <Link to="#" onClick={() => setVideo(med?.url)}>
                                                {med?.thumbnail ?
                                                    <img
                                                        height="166px"
                                                        width="100%"
                                                        src={med.thumbnail}
                                                        alt=""
                                                        className="d-block"
                                                    />
                                                    :
                                                    <VideoThumbnail
                                                        videoUrl={med?.url}
                                                        // thumbnailHandler={(thumbnail) => console.log("THUMB", thumbnail)}
                                                        // width={"100%"}
                                                        // height={"180px"}
                                                        // snapshotAtTime={1}
                                                    />
                                                }
                                            </Link>
                                        )}
                                    </div>
                                    <div className="media-box-body border-top">
                                        <Row>
                                            <Col lg={10}>
                                                <div className="pt-2 pl-1" style={{fontSize: "0.875rem"}}>
                                                    <span style={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 1,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden'
                                                    }}>{med.name}</span>
                                                    <RenderType type={med?.type}/>
                                                    <span className="d-block text-subtitle">
                            <small>{`Uploaded by ${med?.User?.first_name} ${med?.User?.last_name} on 
                              ${moment(med.createdAt).format("MMM DD, YYYY")}`}
                            </small>
                          </span>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="text-center media-delete float-right"
                                                     onClick={() => onDelete(med.id)}>
                                                    <i className="fa fa-trash d-block"></i>
                                                    <span className="d-block">
                            <small>Delete</small>
                          </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                ) : (
                    <Col style={{textAlignLast: "center"}}>No data To Display</Col>
                )}
                <Modal
                    centered={true}
                    show={video ? true : false}
                    onHide={() => {
                        setVideo(undefined)
                    }}
                >
                    <video src={video} controls width="100%"/>
                </Modal>
                <Modal
                    centered={true}
                    show={deleteModal}
                    onHide={() => {
                        setDeleteModal(undefined)
                    }}
                >
                    <DeletePrompt
                        title={"Are you sure you want to delete this media?"}
                        func={() => deleteMedia()}
                        toggle={() => {
                            setDeleteModal(undefined)
                        }}
                    />
                </Modal>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        media: getCompanyMedia(state),
        responseMessage: getMeiaAddedMessage(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addCompanyMedia: (data, props, cb) => dispatch(addCompanyMediaAction(data, props.history, cb)),
        loadCompanyMedia: (data, props, cb) => dispatch(getCompanyMediaAction(data, props.history, cb)),
        deleteCompanyMedia: (data, props, cb) => dispatch(deleteCompanyMediaAction(data, props.history, cb)),
        uploadMediaToAWS: (data, props, cb) => dispatch(uploadMediaToAWSAction(data, props.history, cb)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaLibrary))
const options = {
    settings: {
        disableKeyboardControls: true,
        disableWheelControls: true,
    },
    buttons: {
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false,
        size: "40px",
    },
    thumbnails: {
        showThumbnails: false,
    },
}
