import React, { Fragment, useEffect, useState } from "react"
import { Card, Modal, Badge, Col, Form, Row } from "reactstrap"
import Dropzone from "react-dropzone"

const UploadBox = (props) => {
  return (
    <Col lg="12" className='disabled'>
      <span className="mb-2 d-block disabled">Maximum Video Size Limit: 34MB; Max Image Size Limit: 2MB</span>
      <Form className={'disabled'}>
        <Dropzone
            disabled={true}
          onDrop={(acceptedFiles) => props.handleAcceptedFiles(acceptedFiles)}
          accept={"image/png, image/gif, image/jpg, image/jpeg, video/mp4, video/mov"}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone disabled" style={{ cursor: "unset" }}>
              <div className="dz-message needsclick disabled" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message disabled" >
                  <div className="mb-3 mt-4 disabled">
                    <i className="display-4  fa fa-cloud-upload disabled" style={{ color: "grey" }} />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3 disabled" id="file-previews"></div>
      </Form>
    </Col>
  )
}

export default UploadBox
